<template>
	<div class="user-profile">
		<v-row no-gutters dense align="stretch" class="d-flex" style="height:inherit">
			<v-col cols="12" class="col-md-6 pt-2 my-pr">
				<app-my-information></app-my-information>
			</v-col>
			<v-col cols="12" class="col-md-6 pt-2 my-pl">
				<app-password></app-password>
			</v-col>
		</v-row>
	</div>
</template>

<script>
const MyInformation = () => import("./MyInformation");
const Password = () => import("./Password");
export default {
	components: {
		AppMyInformation: MyInformation,
		AppPassword: Password
	}
};
</script>

<style lang="scss" scoped>
.user-profile {
	height: 100% !important;
}
</style>